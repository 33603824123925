import React from 'react';
import { useSelector } from 'react-redux';
import '../assets/style/mainSection.css';
import image from '../assets/images/SiggyBYogaPose.png';
import logo from '../assets/images/Siggy_Logo.png';
import { Badge, Text, Card,CardBody,Image} from '@chakra-ui/react';

const MainSection = () => {
  const userData =useSelector(state=>state.UserData.user)
  const classCreditData = userData ? userData.packages : [];
  const totalClassCredit =  classCreditData? classCreditData.reduce((acc, packageItem) => acc + packageItem.ac, 0):0;
;

return (
  <div className="MainSection">
    {userData ? (
      <Text fontSize="xl" fontWeight="bold">
        Your Class Credit is
        <Badge
          ml="1"
          fontSize="0.8em"
          colorScheme={totalClassCredit > 0 ? "green" : "red"}
        >
          {totalClassCredit}
        </Badge>
      </Text>
    ) : (
      ""
    )}
    <Card align="center" className="card-container" padding="0" mb="10">
      <CardBody className="content-container" padding="0">
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          <div style={{ position: "absolute", zIndex: 2 ,float:'left'}}>
            <Image src={logo} alt="Logo" style={{ maxWidth: "100px", marginTop: "10px", marginLeft:"15px", border: "3px solid grey" }} />
          </div>
          <div style={{ width: "100%", position: "relative" }}>
            <div style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.5)",
              zIndex: 1,
            }}></div>
            <img src={image} alt="" style={{ width: "100%", display: "block", zIndex: 0 }} />
          </div>
        </div>
      </CardBody>
    </Card>
  </div>
);
}

export default MainSection;
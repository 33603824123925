import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { REACT_APP_STRIPE_KEY } from '../../Config/Config.js';
import LoginForm from './../Forms/LoginForm';
import RegisterForm from './../Forms/RegisterForm';

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [packageStatus, setPackageStatus] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();
  const { isOpen: isRegisterOpen, onOpen: onRegisterOpen, onClose: onRegisterClose } = useDisclosure();
  const packagesData = useSelector(state => state.packages.packages);
  const userPackages = useSelector(state => state.UserData.user?.packages || []);
  const user = useSelector(state => state.UserData.user);
  const purchasedPackageIds = useState(new Set())[0]; // to keep track of purchased package ids

  useEffect(() => {
    setPackages(packagesData);
  }, [packagesData, packageStatus]);

  const handlePurchase = async (packageId) => {
    setPackageStatus(prev => ({ ...prev, [packageId]: { isLoading: true, alertMessage: '', alertStatus: '' } }));

    try {
      const response = await axios.post('https://siggybyoga.femtoweb.co.uk/api/payments/create_package_checkout_session/', {
        package_id: packageId,
        id: user.user.id, // Include the user's ID
      });
      const sessionId = response.data.sessionId;
      const stripe = await loadStripe(REACT_APP_STRIPE_KEY);

      if (!stripe) {
        throw new Error("Stripe.js hasn't loaded yet.");
      }

      const result = await stripe.redirectToCheckout({ sessionId });
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error initiating package purchase:', error.message);
      setPackageStatus(prev => ({ ...prev, [packageId]: { isLoading: false, alertMessage: 'Failed to initiate package purchase.', alertStatus: 'error' } }));
    }
  };

  // Function to check if the user already owns the package
  const userOwnsPackage = (packageId) => {
    return userPackages.some(p => p.package_id === packageId) || purchasedPackageIds.has(packageId);
  };

  const checkIfSignedIn = (packageId) => {
    if (!user) {
      onOpen();
      return;
    }
    handlePurchase(packageId)
  }

  return (
    <div className='packages-main-container'>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader

          >Login or Register in order to purchase a package</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ textAlign: 'center' }}>
            <Button
              color='#af968c' borderColor='#af968c' variant="outline" onClick={() => { onLoginOpen(); onClose(); }}>Log in</Button>
            <Button backgroundColor='#af968c'
              color='white' variant="solid" onClick={() => { onRegisterOpen(); onClose(); }} ml={3}>Register</Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isLoginOpen} onClose={onLoginClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Login</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LoginForm />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isRegisterOpen} onClose={onRegisterClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Register</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RegisterForm />
          </ModalBody>
        </ModalContent>
      </Modal>
      <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
        {packages.length > 0 ? packages.map((packageItem) => (
          <Card key={packageItem.id}>
            <CardHeader>
              <Heading size='md'>{packageItem.package_name}</Heading>
            </CardHeader>
            <CardBody>
              <Text>{packageItem.num_classes} Classes {packageItem.free_classes > 0 ? `+ ${packageItem.free_classes} Free` : ''} </Text>
              <Text>Price: £{packageItem.price}</Text>
              <Text>Expiry: {packageItem.expiry_duration} months</Text>
            </CardBody>
            <CardFooter>
              {packageStatus[packageItem.id]?.isLoading ? (
                <Spinner color="blue.500" size="xl" />
              ) : (
                <Button
                  backgroundColor='#af968c'
                  color='white'
                  onClick={() => checkIfSignedIn(packageItem.id)}
                  isDisabled={userOwnsPackage(packageItem.id)} // Disable the button if user owns the package
                  style={{width:'100%'}}
                >
                  {userOwnsPackage(packageItem.id) ? 'Already Owned' : 'Buy Package'}
                </Button>
              )}
            </CardFooter>
            {packageStatus[packageItem.id]?.alertMessage && (
              <Alert status={packageStatus[packageItem.id].alertStatus} style={{ width: '100%' }}>
                <AlertIcon />
                <AlertDescription>{packageStatus[packageItem.id].alertMessage}</AlertDescription>
              </Alert>
            )}
          </Card>
        )) : <Heading size='md'>
          No packages currently available, please check again later
        </Heading>}
      </SimpleGrid>
    </div>
  );
}

export default Packages;

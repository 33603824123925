// store.js
import { combineReducers } from 'redux';
import yogaClassesReducer from './ClassesReducers.js'; // Corrected import
import authReducer from './RegisterReducer.js'
import yogaPackagesReducer from './PackagesReducer.js';
import specialEventsReducer from './SpecialEventsReducers.js';
const rootReducer = combineReducers({
    yogaClasses: yogaClassesReducer,
    UserData: authReducer, 
    packages:yogaPackagesReducer,
    specialEvents:specialEventsReducer,
});

export default rootReducer;

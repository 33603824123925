import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Heading,
    Stack,
    StackDivider,
    Text,
    AlertDialog,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Alert,
    ButtonGroup,
    Spinner,
    Input,
    Textarea,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Select,
    CardFooter,
    Image,
    Flex,
    Avatar,
    Fade
} from '@chakra-ui/react'
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons';
import LoginForm from './../Forms/LoginForm';
import RegisterForm from './../Forms/RegisterForm';
//import axiosInstance from '../../Helpers/csrfGenerator.js'
import DOMPurify from 'dompurify';
import { REACT_APP_STRIPE_KEY } from '../../Config/Config'
import { loadStripe } from '@stripe/stripe-js';
import logo from '../../assets/images/Siggy_Logo.png';
const SpecialEvents = (data) => {
    const cancelRef = useRef();
    const [sentModifyRequest, setSentModifyRequest] = useState('');
    const [showModifyForm, setShowModifyForm] = useState(false);
    const [isSendingModifyForm, setSendingModifyForm] = useState(false);
    const [modifyFormData, setModifyFormData] = useState({
        fullName: '',
        emailAddress: '',
        content: '',
    })
    const [isCanceling, setIsCanceling] = useState(false);
    const [bookingStates, setBookingStates] = useState({});
    const [bookingSuccess, setBookingSuccess] = useState(false);
    const onCloseCancelDialog = () => setIsCanceling(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();
    const { isOpen: isRegisterOpen, onOpen: onRegisterOpen, onClose: onRegisterClose } = useDisclosure();
    const { isOpen: showPrice, onToggle } = useDisclosure();

    const [errorMessage, setErrorMessage] = useState('');
    const [isBreakdownOpen, setIsBreakdownOpen] = useState(false);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [paymentBreakdown, setPaymentBreakdown] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showSpecialRetreatModal, setShowSpecialRetreatModal] = useState(false);
    const [showInfo,setShowInfo] = useState(false);
    const onBreakdownOpen = () => setIsBreakdownOpen(true);
    const onBreakdownClose = () => setIsBreakdownOpen(false);
    


    const [selectedPayments, setSelectedPayments] = useState({});
    const user = useSelector(state => ({
        ...state.UserData.user,
        booked_events: state.UserData.special_events // Ensure this is part of your Redux state or however you store user data
    }));
    const userData = useSelector(state => state.UserData.user)

    const [guestCounts, setGuestCounts] = useState({});
    const calculateRemainingWeeks = (eventDate) => {
        const today = new Date();
        const eventDateObj = new Date(eventDate);
        const diffTime = Math.abs(eventDateObj - today);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return Math.floor(diffDays / 7);
    };

    const determinePaymentOptions = (remainingWeeks) => {
        if (remainingWeeks >= 14) {
            return [1, 2, 3];
        } else if (remainingWeeks > 10) {
            return [1, 2];
        } else {
            return [1];
        }
    };
    const handlePaymentsChange = (eventId, value) => {
        setSelectedPayments(prev => ({ ...prev, [eventId]: parseInt(value) }));
    };
    const handleGuestsChange = (eventId, value) => {
        setGuestCounts(prev => ({ ...prev, [eventId]: parseInt(value) }));
    };
    useEffect(() => {
        if (user && data.data) {
            const initialStates = {};
            data.data.forEach(event => {
                initialStates[event.id] = {
                    isBooking: false,
                    bookingSuccess: false,
                    isAlreadyBooked: user.special_events && user.special_events.includes(event.id)
                };
            });
            setBookingStates(initialStates);
        }
    }, [bookingSuccess]);




    useMemo(() => {
        data.data.forEach(event => {

            // Check if the object has a prices array
            if (event.prices && Array.isArray(event.prices)) {
                // Sort the prices array based on their amount

                event.prices.sort((a, b) => {
                    // Convert the amount from string to number for comparison
                    const amountA = parseFloat(a.amount);
                    const amountB = parseFloat(b.amount);

                    // Compare the amounts
                    if (amountA < amountB) {
                        return -1;
                    } else if (amountA > amountB) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }

        })
    }, [data]);

    const handleCancelClass = async () => {
        onCloseCancelDialog();
        try {
            const response = await axios.post(`https://siggybyoga.femtoweb.co.uk/api/classes/cancel/`, {}, { withCredentials: true });
            if (response.status === 200) {
                console.log(response.data.message);  // "Class booking cancelled and credited successfully"
                setBookingSuccess(false);
                alert('Class cancelled successfully and credited back to your account.');
            } else {
                throw new Error(response.data.error || 'Failed to cancel class.');
            }
        } catch (error) {
            console.error("Error canceling class:", error.response?.data?.error || error.message);
            alert("Error canceling class: " + (error.response?.data?.error || error.message));
        }
    };


    const handleStripe = async (specialevents, priceId) => {
        const selectedPrice = specialevents.prices.find(price => price.id === priceId);
        if (!selectedPrice) {
            console.error("Selected price not found.");
            setErrorMessage("The selected price is not available.");
            return;
        }

        const selectedPaymentOption = selectedPayments[specialevents.id] || 1; // Default to 1 payment if not selected
        const numberOfGuests = guestCounts[specialevents.id] || 1; // Default to 1 guest if not selected
        let totalPrice;
        if (selectedPaymentOption === 1) {
            // Use the one-time payment price
            totalPrice = parseFloat(selectedPrice.one_time_payment_amount) * numberOfGuests;
        } else {
            // Use the deposit and installments price
            totalPrice = parseFloat(selectedPrice.amount) * numberOfGuests;
        }
        try {
            const response = await axios.post('https://siggybyoga.femtoweb.co.uk/api/payments/create_special_event_session/', {
                event_id: specialevents.id,
                name: specialevents.name,
                price: totalPrice,
                event_date: specialevents.date,
                selected_payments: selectedPaymentOption,
                user_id: user.user.id,
            });

            const sessionId = response.data.sessionId;
            const stripe = await loadStripe(REACT_APP_STRIPE_KEY);

            if (!stripe) {
                console.error("Stripe.js hasn't loaded yet.");
                setErrorMessage("The payment service is currently unavailable. Please try again later.");
                return;
            }

            const result = await stripe.redirectToCheckout({ sessionId });
            if (result.error) {
                console.error("Stripe Checkout error:", result.error);
                setErrorMessage(result.error.message);
            }

        } catch (error) {
            console.error("Error while creating Stripe checkout session:", error);
            setErrorMessage("An error occurred during payment processing.");
        }
    };





    const handleBooking = async (specialevents, priceId) => {
        if (!userData) {
            onOpen();
            return;
        }

        const selectedPrice = specialevents.prices.find(price => price.id === priceId);
        const selectedPaymentOption = selectedPayments[specialevents.id] || 1; // Default to 1 payment if not selected
        const numberOfGuests = guestCounts[specialevents.id] || 1; // Default to 1 guest if not selected

        let breakdown;
        if (selectedPaymentOption === 1) {
            const totalPrice = selectedPrice.one_time_payment_amount * numberOfGuests;
            breakdown = `Full payment: £${totalPrice}`;
        } else {
            const depositAmount = specialevents.deposit * numberOfGuests;
            const installmentAmount = parseFloat(selectedPrice.amount) * numberOfGuests;
            const remainingAmount = installmentAmount - depositAmount;
            const monthlyInstallment = remainingAmount / selectedPaymentOption;

            const today = new Date();
            const installmentDates = [...Array(selectedPaymentOption)].map((_, i) => {
                const installmentDate = new Date(today);
                installmentDate.setMonth(today.getMonth() + i + 1);
                return `Installment ${i + 1}: £${monthlyInstallment.toFixed(2)} on ${installmentDate.toLocaleDateString()}`;
            });

            breakdown = `Deposit (Today): £${depositAmount.toFixed(2)}\n${installmentDates.join('\n')}`;
        }

        showBreakdownModal({ specialevents, priceId }, breakdown);
    };

    const bookClass = async () => {
        setIsLoading(true);
        await handleStripe(currentEvent.specialevents, currentEvent.priceId);
        setIsLoading(false);
        onBreakdownClose(); // Close the breakdown modal
    };

    const showBreakdownModal = (event, breakdown) => {
        setCurrentEvent(event);
        setPaymentBreakdown(breakdown.split('\n'));
        onBreakdownOpen();
    };



    const ModifySpecialEvent = async (eventId) => {
        setSendingModifyForm(true);
        const modifyData = {
            ...modifyFormData,
            eventId: eventId  // Include the event ID in the modification request
        };
        try {
            const response = await axios.post('https://siggybyoga.femtoweb.co.uk/api/specialevents/modify/', modifyData);
            console.log(response);
            if (response.status === 200) {
                setSentModifyRequest(true);
                setShowModifyForm(false);
            } else {
                setSentModifyRequest(false);
            }
        } catch (error) {
            console.error('Error sending modification request:', error);
            alert('Error sending modification request: ' + error.message);
        } finally {
            setSendingModifyForm(false);
        }
    }

    const ShowModifyForm = () => {
        setShowModifyForm(!showModifyForm)
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setModifyFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const formatDescription = (description) => {
        return DOMPurify.sanitize(description.replace(/\\r\\n/g, '<br/>'));
    };


    return (
        <div>

            <AlertDialog isOpen={isCanceling} leastDestructiveRef={cancelRef} onClose={onCloseCancelDialog}>
            </AlertDialog>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Login or Register in Order to secure your place in our event</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Button color='#af968c' borderColor='#af968c' variant="outline" onClick={() => { onLoginOpen(); onClose(); }}>Log in</Button>
                        <Button color='white' backgroundColor='#af968c' variant="solid" onClick={() => { onRegisterOpen(); onClose(); }} ml={3}>Register</Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* Login Modal */}
            <Modal isOpen={isLoginOpen} onClose={onLoginClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Login</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <LoginForm />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* Register Modal */}
            <Modal isOpen={isRegisterOpen} onClose={onRegisterClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Register</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <RegisterForm />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isBreakdownOpen} onClose={onBreakdownClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Payment Breakdown</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {paymentBreakdown.map((line, index) => (
                            <Text key={index} display="block">{line}</Text>
                        ))}
                    </ModalBody>
                    <ModalFooter>

                        <Button variant='solid'
                            backgroundColor='#af968c'
                            color='white' onClick={bookClass} isLoading={isLoading} style={{ marginRight: '15px' }}>
                            {isLoading ? <Spinner size="sm" /> : 'Proceed to Payment'}

                        </Button>

                        <Button colorScheme="red" onClick={onBreakdownClose}>Cancel</Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>


            {data.data.map((specialevents) => {
                const remainingWeeks = calculateRemainingWeeks(specialevents.date);
                const paymentOptions = determinePaymentOptions(remainingWeeks);

                return (
                    <>
                        <Card key={specialevents.id}>
                            {bookingStates[specialevents.id]?.bookingSuccess && <Alert status="success">Booked Successfully!</Alert>}


                            <br></br>

                            <Card
                                direction={{ base: 'column', sm: 'row' }}
                                overflow='hidden'
                                variant='outline'
                            >
                                <Image
                                    objectFit='cover'
                                    maxW={{ base: '100%', sm: '200px' }}
                                    src={specialevents.full_image_url ? specialevents.full_image_url : logo}
                                    alt={specialevents.name}
                                />

                                <Stack>
                                    <CardBody>
                                        <Heading size='md'> {specialevents.name}</Heading>
                                        <Flex>
                                            <Avatar src={logo} />
                                            <Box ml='2'>
                                                <Text>
                                                    {specialevents.instructor}
                                                </Text>
                                            </Box>
                                        </Flex>
                                    </CardBody>
                                    {
                                         bookingStates[specialevents.id]?.isAlreadyBooked ?
                                         <>
                                             {showModifyForm && (
                                                 <Card>
                                                     <CardHeader>Please Fill In The Following Details:</CardHeader>
                                                     <CardBody>
                                                         <Stack spacing={3}>
                                                             <Input placeholder='Full Name' name="fullName" size='md' onChange={(e) => { handleChange(e) }} />
                                                             <Input placeholder='Email Address' name="emailAddress" size='md' onChange={(e) => { handleChange(e) }} />
                                                             <Textarea placeholder='Your Message' name="content" size='md' onChange={(e) => { handleChange(e) }} />
                                                             <Button colorScheme='teal' variant='outline' onClick={() => ModifySpecialEvent(specialevents.id)}>
                                                                 {isSendingModifyForm ? <Spinner size="sm" /> : 'Submit'}
                                                             </Button>
                                                         </Stack>
                                                     </CardBody>
                                                 </Card>
                                             )}

                                             {sentModifyRequest && (
                                                 <Alert
                                                     status='success'
                                                     variant='subtle'
                                                     flexDirection='column'
                                                     alignItems='center'
                                                     justifyContent='center'
                                                     textAlign='center'
                                                     height='200px'
                                                 >
                                                     <AlertIcon boxSize='40px' mr={0} />
                                                     <AlertTitle mt={4} mb={1} fontSize='lg'>
                                                         Modification request sent successfully!
                                                     </AlertTitle>
                                                     <AlertDescription maxWidth='sm'>
                                                         Thanks your for contacting SiggyBYoga. Our team will get back to you soon.
                                                     </AlertDescription>
                                                 </Alert>
                                             )}

                                             <br></br>
                                             <Button colorScheme='teal' variant='outline' onClick={() => setShowInfo(true)}>
                                                                 {'Info'}
                                                             </Button>
                                             <Button color='#af968c' variant='outline' borderColor='#af968c' onClick={() => ShowModifyForm()} ml={3}>
                                                 {showModifyForm ? 'Cancel' : 'Modify Event'}
                                             </Button>
                                             <Button colorScheme="red" onClick={() => handleCancelClass(specialevents.id)} ml={3}>
                                                 Cancel Event
                                             </Button>
                                             </>
                                             :
                                             <CardFooter>
                                             <Button color='white' backgroundColor='#af968c' variant="solid" width='100%' onClick={() => { setShowSpecialRetreatModal(true) }}>
                                                 Learn More
                                             </Button>
                                         </CardFooter>
                                    }

                                </Stack>
                            </Card>
                        </Card>
                        <Modal isOpen={showInfo} onClose={()=>{setShowInfo(false)}} >
                        <ModalOverlay />
                           
                            <ModalHeader>
                                Info
                            </ModalHeader>
                            <ModalContent>
                            <ModalCloseButton />
                            <div pb={4} style={{padding:'24px'}}>
                                        <div dangerouslySetInnerHTML={{ __html: formatDescription(specialevents.description) }} />
                                    </div>
                            </ModalContent>
                        </Modal>
                        <Modal isOpen={showSpecialRetreatModal} onClose={() => { setShowSpecialRetreatModal(false) }}>
                            <ModalOverlay />
                           
                            <ModalHeader>
                                About
                            </ModalHeader>
                            <ModalContent>
                            <ModalCloseButton />
                                <div key={specialevents.id}
                                    style={{ padding: '24px' }}
                                >

                                    {errorMessage && <Alert status="error">{errorMessage}</Alert>}
                                    <br />
                                    <h2>
                                    </h2>
                                    <div pb={4}>
                                        <div dangerouslySetInnerHTML={{ __html: formatDescription(specialevents.description) }} />
                                    </div>


                                    <div>
                                        <CalendarIcon /> {specialevents.date}
                                    </div>
                                    <div>
                                        <TimeIcon /> {specialevents.duration}
                                    </div>
                                    <div>
                                        Available Spots: {specialevents.available_spots}
                                    </div>

                                    <div>
                                        {
                                           
                                                
                                                <>
                                                    <br />
                                                    <Button onClick={onToggle} color='#af968c' variant='outline' borderColor='#af968c' width='100%'>{showPrice ? 'Hide Pricing' : 'Show Pricing'}</Button>

                                                    <Fade in={showPrice}>
                                                        <br />
                                                        <Heading size='md'>Prices:</Heading>


                                                        <Stack divider={<StackDivider />} spacing='4'>
                                                            {specialevents.prices.map((price) => (
                                                                <Box key={price.id}>
                                                                    <Heading size='xs' textTransform='uppercase'>
                                                                        {price.title} - £ {price.amount} {price.one_time_payment_amount > 0 ? `in payments or ${price.one_time_payment_amount} in one payment` : ''}
                                                                    </Heading>
                                                                    <Text pt='2' fontSize='sm'>{price.description}</Text>

                                                                    <label>How many will you be? </label>
                                                                    <Select placeholder="Select number" onChange={(e) => handleGuestsChange(specialevents.id, e.target.value)}>
                                                                        {[...Array(price.max_guests).keys()].map((num) => (
                                                                            <option key={num + 1} value={num + 1}>
                                                                                {num + 1}
                                                                            </option>
                                                                        ))}
                                                                    </Select>
                                                                    <br />
                                                                    <label>Select number of payments:</label>
                                                                    <Select placeholder="Select payments" onChange={(e) => handlePaymentsChange(specialevents.id, e.target.value)}>
                                                                        {paymentOptions.map(option => (
                                                                            <option key={option} value={option}>
                                                                                {option}
                                                                            </option>
                                                                        ))}
                                                                    </Select>
                                                                    <br />
                                                                    <ButtonGroup spacing='2' >
                                                                        <Button
                                                                            onClick={() => handleBooking(specialevents, price.id)}
                                                                            isDisabled={bookingStates[specialevents.id]?.isBooking && bookingStates[specialevents.id].bookingPriceId === price.id}
                                                                            backgroundColor='#af968c'
                                                                            color='white' variant="solid"
                                                                        >
                                                                            {bookingStates[specialevents.id]?.isBooking && bookingStates[specialevents.id].bookingPriceId === price.id ? <Spinner size="sm" /> : 'Book now'}
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                </Box>
                                                            ))}
                                                        </Stack>

                                                    </Fade>
                                                </>}
                                    </div>
                                </div>
                            </ModalContent>
                        </Modal>

                    </>

                );
            })}

        </div>
    )
}

export default SpecialEvents
import React, { useState } from 'react';
import './App.css';
import Header from './components/Header.jsx';
import MainSection from './components/MainSection.jsx';
import Footer from './components/Footer.jsx';
import AllTabs from './components/Tabs/Tabs.jsx';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PaymentSuccess from './components/Payments/PaymentSuccess.jsx';
import PackagePaymentSuccess from './components/Payments/PackagePaymentSuccess.jsx'
function App() {
 

  return (
    <ChakraProvider>
      <Router>
        <div className="App">
          <div className='Main-App-Container max-width-container'>
            <Header />
            <Routes>
              <Route path="/" element={
                <>
                  <MainSection />
                  <AllTabs  />
                </>
              } />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/payment-success-package" element={<PackagePaymentSuccess />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;

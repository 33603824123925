import React, { useEffect } from 'react';
import axios from 'axios';
import { Button, Box, Heading, Text, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const PackagePaymentSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionId = queryParams.get('session_id');
    const packageId = queryParams.get('package_id');  // Ensure this is passed as a query param in your Stripe success URL

    // Function to finalize the purchase of the package
    const finalizePackagePurchase = async () => {
      try {
        const response = await axios.post(`https://siggybyoga.femtoweb.co.uk/api/packages/purchase-package/`, 
          { package_id: packageId }, 
          { withCredentials: true }
        );

        if (response.status === 200) {
          console.log('Package purchased successfully');
          // Redirect or update UI as needed
          navigate('/'); // or to a specific "success" page
        } else {
          throw new Error('Purchase was not successful.');
        }
      } catch (error) {
        console.error("Error finalizing package purchase:", error);
      }
    };

    if (sessionId && packageId) {
      finalizePackagePurchase();
    }
  }, [navigate]);

  return (
    <Center h="100vh" flexDirection="column">
      <Box textAlign="center" py={10} px={6}>
        <Heading display="inline-block" as="h2" size="2xl" bgGradient="linear(to-r, teal.400, teal.600)" backgroundClip="text">
          Payment Successful!
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Your payment has been processed successfully. Thank you for your purchase!
        </Text>
        <Text color={'gray.500'}>
          You are now subscribed to the package. Get ready to enjoy your classes!
        </Text>
        <Button colorScheme="teal" variant="solid" mt={6} onClick={() => navigate('/')}>
          Go to Homepage
        </Button>
      </Box>
    </Center>
  );
};

export default PackagePaymentSuccess;

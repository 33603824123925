import React from 'react'
import { Card, CardHeader, Heading, CardBody, Stack, StackDivider, Box, Text } from '@chakra-ui/react';
import { PhoneIcon, EmailIcon, LinkIcon } from '@chakra-ui/icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Contact = () => {
  return (
    <Card>

      <CardBody>
        <Stack divider={<StackDivider />} spacing='4'>
          <Box>
            <Heading size='xs' textTransform='uppercase'>
              Phone Number
            </Heading>
            <Text pt='2' fontSize='sm' type='tel'>
              <PhoneIcon />  {" "} 07971607892
            </Text>
          </Box>
          <Box>
            <Heading size='xs' textTransform='uppercase'>
              Email
            </Heading>
            <Text pt='2' fontSize='sm'>
              <EmailIcon /> siggybyoga@gmail.com
            </Text>
          </Box>
          <Box>
            <Heading size='xs' textTransform='uppercase'>
              Website
            </Heading>
            <Text pt='2' fontSize='sm'>
              <LinkIcon /> <a href='https://www.siggybyoga.co.uk'>https://www.siggybyoga.co.uk</a>
            </Text>
          </Box>
          <Box>
            <Heading size='xs' textTransform='uppercase'>
              Follow Us
            </Heading>
            <Text pt='2' fontSize='sm'>
              <a href='https://www.facebook.com/YourFacebookPage'>
                <FontAwesomeIcon icon={faFacebook} /> Facebook
              </a>
            </Text>
            <br></br>
            <Text>
              <a href='https://www.instagram.com/YourInstagram'>
                <FontAwesomeIcon icon={faInstagram} /> Instagram
              </a>
            </Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  )
}

export default Contact
import React from 'react'
import ClassList from './Classes/ClassList';
const Schedule = ({ selectedDay }) => {
  return (
      <div className="schedule">
        <ClassList selectedDay={selectedDay} />
      </div>
    );
}

export default Schedule
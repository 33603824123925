
import { logout } from "../Actions/RegisterActions";

let logoutTimer;
export const resetLogoutTimer = (dispatch) => {
  clearTimeout(logoutTimer);
  logoutTimer = setTimeout(() => {
    dispatch(logout());
  }, 18000000); // 5 hours in milliseconds
};

export const clearLogoutTimer = () => {
  clearTimeout(logoutTimer);
};


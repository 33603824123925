import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Calendar } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import Schedule from '../Schedule.jsx';
import '../../assets/style/calendar.css';

const Calender = () => {
  const classesData = useSelector(state => state.yogaClasses.classes);
  
  const today = new Date();
  const defaultSelectedDay = {
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
  };
  
  const [selectedDay, setSelectedDay] = useState(defaultSelectedDay);
  const [customDaysClassNames, setCustomDaysClassNames] = useState([]);

  useEffect(() => {
    // Assuming classesData is an array of objects and each object has a date property
    // in the format of 'YYYY-MM-DD'
    const classDays = classesData.map(classItem => {
      const [year, month, day] = classItem.date.split('-').map(Number);
      return {
        year,
        month,
        day,
        className: 'lessonDay' // This is the CSS class that will be applied
      };
    });

    setCustomDaysClassNames(classDays);
  }, [classesData]); // This will recalculate when classesData changes

  return (
    <div>
      <Calendar
        value={selectedDay}
        onChange={setSelectedDay}
        customDaysClassName={customDaysClassNames}
        // Add additional props as needed
      />
      <Schedule selectedDay={selectedDay} />
    </div>
  );
};

export default Calender;

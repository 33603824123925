// authActions.js
import {clearLogoutTimer } from '../Utility/Logout.js'
// Action Types
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
// Action Creators
export const registerSuccess = (userData) => ({
  type: REGISTER_SUCCESS,
  payload: userData,
});

export const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: userData, // Include user data in payload
});

export const logout = () => {
  clearLogoutTimer(); // Clear the logout timer
  return {
    type: LOGOUT,
  };
};
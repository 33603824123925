const initialState = {
    packages: []
};

const yogaPackagesReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'SET_PACKAGES':
            return { ...state, packages: action.payload };
        default:
            return state;
    }
};

export default yogaPackagesReducer;
import React, { useState, useRef, useEffect } from 'react';
import { useSelector} from 'react-redux';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { Card, CardBody, CardFooter, Image, Stack, Heading, Text, Divider, ButtonGroup, Button, Spinner, Alert,Avatar,Box,Flex,HStack } from '@chakra-ui/react';
import axios from 'axios';
import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import {TimeIcon} from '@chakra-ui/icons';
import LoginForm from './../Forms/LoginForm';
import RegisterForm from './../Forms/RegisterForm';
import { REACT_APP_STRIPE_KEY } from '../../Config/Config';
import { loadStripe } from '@stripe/stripe-js';
import logo from '../../assets/images/SiggyBYogaPose.png';
import '../../assets/style/ClassItem.css'
const ClassItem = ({ name, time, instructor, available_spots, description, date, id, full_image_url, price, booked_participants, max_participants, duration, isBooked, onBookingSuccess, onCancelSuccess }) => {
  const [isCanceling, setIsCanceling] = useState(false);
  const [isCancelingClass, setIsCancelingClass] = useState(false);
  const [cancelMessage, setCancelMessage] = useState('');
  const cancelRef = useRef();
  const [showCreditUsageModal, setShowCreditUsageModal] = useState(false);
  const [showPaymentOptionModal, setShowPaymentOptionModal] = useState(false);
  const user = useSelector(state => state.UserData.user);
  const classCreditData = useSelector(state => state.UserData.user?.packages || []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();
  const { isOpen: isRegisterOpen, onOpen: onRegisterOpen, onClose: onRegisterClose } = useDisclosure();
  const [readMore,setReadMore] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [isExpired, setIsExpired] = useState(false); // State to determine if the class is expired
  const [diffInMinutes, setDiffInMinutes] = useState(0); // State to store time difference in minutes

  const [errorMessage, setErrorMessage] = useState('');
  const totalClassCredit = classCreditData.reduce((acc, packageItem) => acc + packageItem.ac, 0);
  const onOpenCancelDialog = () => setIsCanceling(true);
  const onCloseCancelDialog = () => setIsCanceling(false);
  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
  const classDate = new Date(date);
  const day = (weekday[classDate.getDay()]);
  const month = months[classDate.getMonth()];
  const [startHour,startMinute] = time.split(':').map(Number);
  const startTime = new Date();
  startTime.setHours(startHour);
  startTime.setMinutes(startMinute);
  const endTime = new Date(startTime.getTime() + duration * 60000);
  const endHour = endTime.getHours();
  const endMinute = endTime.getMinutes();
  const formattedStartMinute = String(startMinute).padStart(2, '0');
  const formattedEndMinute = String(endMinute).padStart(2, '0');
  
  const handleCancelClass = async () => {
    setIsCancelingClass(true);
    onCloseCancelDialog();
    try {
      const response = await axios.post(`https://siggybyoga.femtoweb.co.uk/api/classes/cancel/${id}/`, {}, { withCredentials: true });
      if (response.status === 200) {
        setIsCancelingClass(false);
        setBookingSuccess(false);
        onCancelSuccess(id);
        setCancelMessage('Class cancelled successfully! If you already paid, your account will be credited accordingly');
      } else {
        throw new Error(response.data.error || 'Failed to cancel class.');
      }
    } catch (error) {
      console.error("Error canceling class:", error.response?.data?.error || error.message);
      setErrorMessage("Error canceling class: " + (error.response?.data?.error || error.message));
    }
  };

  const handleUseCredit = async () => {
    setShowCreditUsageModal(false);
    setIsBooking(true);
    try {
      const response = await axios.post(`https://siggybyoga.femtoweb.co.uk/api/classes/book-with-credit/${id}/`, { payment_status: 'paid' }, { withCredentials: true });
      if (response.status === 200) {
        setBookingSuccess(true);
        onBookingSuccess(id);
      } else {
        setErrorMessage("Booking Failed: " + response.data.error);
      }
    } catch (error) {
      setErrorMessage("An error occurred while booking the class.");
      console.error("Booking error:", error);
    }
    setIsBooking(false);
  };

  const handleStripe = async () => {
    try {
      const response = await axios.post('https://siggybyoga.femtoweb.co.uk/api/payments/create_checkout_session/', {
        name: name,
        price: price,
        class_id: id,
        id: user.user.id, // Include the user's email
      });

      const sessionId = response.data.sessionId;
      const stripe = await loadStripe(REACT_APP_STRIPE_KEY);

      if (!stripe) {
        console.error("Stripe.js hasn't loaded yet.");
        setErrorMessage("The payment service is currently unavailable. Please try again later.");
        return;
      }

      const result = await stripe.redirectToCheckout({ sessionId });
      if (result.error) {
        console.error("Stripe Checkout error:", result.error);
        setErrorMessage(result.error.message);
      }
    } catch (error) {
      console.error("Stripe Checkout session creation error:", error);
      setErrorMessage("An error occurred while redirecting to the payment page.");
    }
  };
  const handleBooking = async () => {
    if (!user) {
      onOpen();
      return;
    }

    if (totalClassCredit > 0) {
      setShowCreditUsageModal(true);
    } else {
      setShowPaymentOptionModal(true);
    }
  };

  const bookClass = async () => {
    handleStripe();
    setIsBooking(true);
  };

  const handlePayAtDoor = async () => {
    setShowPaymentOptionModal(false);
    setIsBooking(true);
    try {
      const response = await axios.post(`https://siggybyoga.femtoweb.co.uk/api/classes/book/${id}/`, { payment_status: 'cash' }, { withCredentials: true });
      if (response.status === 200) {
        setBookingSuccess(true);
        onBookingSuccess(id);
      } else {
        setErrorMessage("Booking Failed: " + response.data.error);
      }
    } catch (error) {
      setErrorMessage("An error occurred while booking the class.");
      console.error("Booking error:", error);
    }
    setIsBooking(false);
  };
  
  useEffect(() => {
    // Recalculate start time whenever date or time changes
    const calculateTimeDifference = () => {
      const classDate = new Date(date); // Set class date
      const [startHour, startMinute] = time.split(':').map(Number); // Parse time
      const startTime = new Date(classDate); // Create a new Date object for start time
      startTime.setHours(startHour, startMinute, 0, 0); // Set hours and minutes

      const now = new Date(); // Current time
      const diffInMinutes = Math.round((startTime - now) / (1000 * 60)); // Calculate difference in minutes (negative if past)

      // console.log('Start Time:', startTime, 'Current Time:', now, 'Difference:', diffInMinutes); // Debugging

      setDiffInMinutes(diffInMinutes);
      setIsExpired(diffInMinutes <= 0); // Set expired state if the class has already started or is about to start in the next 30 minutes
    };

    calculateTimeDifference(); // Initial calculation
  }, [date, time]); // Include relevant dependencies

  

  return (
    <>
      <AlertDialog isOpen={isCanceling} leastDestructiveRef={cancelRef} onClose={onCloseCancelDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancel Class
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to cancel your booking for this class? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseCancelDialog}>
                No
              </Button>
              <Button colorScheme="red" onClick={handleCancelClass} ml={3}>
                Yes, Cancel it
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Login or Register in Order to book a class</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button color='#af968c' borderColor='#af968c'  variant="outline"onClick={() => { onLoginOpen(); onClose(); }}>Log in</Button>
            <Button color='white' backgroundColor='#af968c'  variant="solid" onClick={() => { onRegisterOpen(); onClose(); }} ml={3}>Register</Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={showCreditUsageModal} onClose={() => setShowCreditUsageModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Use a Class Credit?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            You have {totalClassCredit} credits available. Would you like to use a credit to book this class?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleUseCredit}>
              Use Credit
            </Button>
            <Button colorScheme="yellow" mr={3} onClick={bookClass}>
              Buy This Class
            </Button>
            <Button variant="ghost" onClick={() => setShowCreditUsageModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={showPaymentOptionModal} onClose={() => setShowPaymentOptionModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Payment Options</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            How would you like to pay for this class?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={bookClass}>
              Pay in Advance
            </Button>
            <Button colorScheme="orange" mr={3} onClick={handlePayAtDoor}>
              Pay at the Door
            </Button>
            <Button variant="ghost" onClick={() => setShowPaymentOptionModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isLoginOpen} onClose={onLoginClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Login</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <LoginForm />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isRegisterOpen} onClose={onRegisterClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Register</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RegisterForm />
          </ModalBody>
        </ModalContent>
      </Modal>

      <div className='Card-date-header'>
       <div style={{paddingLeft:'10px'}}>
        {day}{" "}{" "}{date.split('-')[2]}{" "}{" "}{month}
       </div>
      </div>
      <Card maxW='m'>
        <CardBody>
          {bookingSuccess && <Alert status="success">Booked Successfully!</Alert>}
          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
          {cancelMessage && <Alert status="success">{cancelMessage}</Alert>}
          <br />
          
          <Image
            src={full_image_url}
            alt='Class Image'
            size={["100px", "150px", "200px"]}
            className='class-image'
          />
          <Stack mt='6' spacing='3'>
            <Heading size='md'>{name}</Heading>
            <Flex>
              <Avatar src={logo} />
              <Box ml='3'>
                <Text fontWeight='bold'>
                  {instructor}
                </Text>
              </Box>
            
            </Flex>
            <Text>Price: £{price}</Text>
            <Flex>
              <HStack spacing='7px'>
              <TimeIcon/><Text>{startHour}:{formattedStartMinute}-{endHour}:{formattedEndMinute}</Text>
              </HStack>
         
            </Flex>
            <Text>Booked Spots: {booked_participants}{" "}/{" "}{max_participants}</Text>
            {readMore ?
                <div dangerouslySetInnerHTML={{ __html: description }} />
             : 
             <>
              <div dangerouslySetInnerHTML={{ __html: description.split('.')[0]+'....'} } />
      
             </> 
          
             
             }
                  <Button colorScheme='teal' size='xs' onClick={()=>{setReadMore(!readMore)}}>
                  {readMore?'Read Less':'Read More'}
                  </Button>
            {/* <div dangerouslySetInnerHTML={{ __html: description }} /> */}
         
          </Stack>
        </CardBody>
        <Divider />
        <CardFooter>
        {isExpired ? (
          <Text color="red.500" fontSize="lg">
              Oops... Looks like this class has already passed. Don't worry, we have many other amazing classes available for you to choose from!
          </Text>

          ) : (
            <ButtonGroup spacing='2'
              style={{width:'100%'}}
            >
              <Button
                variant='solid'
                backgroundColor='#af968c'
                color='white'
                onClick={() => { handleBooking() }}
                isDisabled={isBooking || bookingSuccess || isBooked}
                style={{width:'100%'}}
              >
                {isBooking ? <Spinner size="sm" /> : bookingSuccess || isBooked ? 'Booked' : 'Book now'}
              </Button>
              {isBooked && (
                <Button colorScheme="red" onClick={onOpenCancelDialog} disabled={!bookingSuccess}>
                  {isCancelingClass ? <Spinner size="sm" /> : 'Cancel Class'}
                </Button>
              )}
            </ButtonGroup>
          )}
        </CardFooter>
      </Card>
      <br /><br />
    </>
  );
}

export default ClassItem;

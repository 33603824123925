import React from 'react';
import { useState,useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Spinner,Heading,HStack  } from '@chakra-ui/react';
import '../assets/style/header.css';
import RegisterForm from './Forms/RegisterForm';
import LoginForm from './Forms/LoginForm';
import { loginSuccess } from '../Redux/Actions/RegisterActions';
import MyAccountForm from './Forms/MyAccountForm';
const Header = () => {
  const dispatch = useDispatch();
  const { isOpen: isLoginOpen, onOpen: onLoginOpen, onClose: onLoginClose } = useDisclosure();
  const { isOpen: isRegisterOpen, onOpen: onRegisterOpen, onClose: onRegisterClose } = useDisclosure();
  const { isOpen: isAccountOpen, onOpen: onAccountOpen, onClose: onAccountClose } = useDisclosure();
  const [accountDetails, setAccountDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // Accessing the state using 'UserData' as per your store configuration
  const user = useSelector(state => state.UserData.user);

  const fetchAccountDetails = async () => {
    setIsLoading(true);
    try {
        const response = await fetch('https://siggybyoga.femtoweb.co.uk/api/myaccount/', {
            method: 'GET',
            credentials: 'include', // To include the HTTPOnly cookie
        });
        if (response.ok) {
            const data = await response.json();
            setAccountDetails(data);
        } else {
            console.error('Failed to fetch account details:', response.statusText);
        }
    } catch (error) {
        console.error('Error fetching account details:', error);
    }
    setIsLoading(false);
};


  const handleLogout = async () => {
    try {
        const response = await fetch('https://siggybyoga.femtoweb.co.uk/api/logout/', {
            method: 'POST',
            credentials: 'include'
          
       
          
        });

        if (response.ok) {
            // Update Redux state and other cleanup if needed
            dispatch(loginSuccess(null));
        } else {
            console.error('Logout failed:', response.status, response.statusText);
        }
    } catch (error) {
        console.error('Logout error:', error);
    }
};


useEffect(() => {
  if (isAccountOpen) {
      fetchAccountDetails();
  }
}, [isAccountOpen]);

  return (
    <header>
      <div className='main-header-container'>
      {user ? (
           <HStack spacing='24px'>
             <Heading as='h5' size='sm'>Welcome, {user.user.first_name}{" "}</Heading>
          
            <Button color='#af968c' borderColor='#af968c' variant="outline" onClick={onAccountOpen}>My Account</Button>
            {" "}
                 <Button backgroundColor='#af968c' variant="solid" color='white' onClick={handleLogout}>Logout</Button>
          

      
       </HStack>
        ) : (
          <>
            <Button color='#af968c' borderColor='#af968c' variant="outline" onClick={onLoginOpen}>Log in</Button>
            <Button backgroundColor='#af968c' variant="solid" onClick={onRegisterOpen} ml={3} color='white'>Register</Button>
          </>
        )}
      {/* My Account Modal */}
      <Modal isOpen={isAccountOpen} onClose={onAccountClose}>
                          <ModalOverlay />
                          <ModalContent>
                              <ModalHeader>My Account</ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                  {isLoading ? (
                                      <Spinner />  // Show loading animation while fetching data
                                  ) : (
                                      <MyAccountForm accountDetails={accountDetails} />
                                  )}
                              </ModalBody>
                          </ModalContent>
                      </Modal>
        {/* Login Modal */}
        <Modal isOpen={isLoginOpen} onClose={onLoginClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Login</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <LoginForm />
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {/* Register Modal */}
        <Modal isOpen={isRegisterOpen} onClose={onRegisterClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Register</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RegisterForm />
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </header>
  );
}

export default Header;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleClientID } from './Config/Config';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import {loginSuccess} from '../src/Redux/Actions/RegisterActions.js'
import { setClasses } from '../src/Redux/Actions/ClassesActions.js';
import { setPackages } from '../src/Redux/Actions/PackagesAction.js';
import { setSpecialEvents } from './Redux/Actions/SpecialEvents';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react';
import rootReducer from './Redux/Reducers/store.js';
import {resetLogoutTimer} from './Redux/Utility/Logout.js';
import { logout } from '../src/Redux/Actions/RegisterActions.js'; // Import your action creators



const root = ReactDOM.createRoot(document.getElementById('root'));
const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer, // using persistedReducer here
  applyMiddleware(thunk)
);

export const persistor = persistStore(store);

fetch('https://siggybyoga.femtoweb.co.uk/api/classes/',{
  method:'GET',
}).then((response)=>{
  return response.json();
}).then(jsonResponse =>{
  store.dispatch(setClasses(jsonResponse))
})


fetch('https://siggybyoga.femtoweb.co.uk/api/packages/',{
  method:'GET',
}).then((response)=>{
  return response.json();
}).then(jsonResponse =>{
  store.dispatch(setPackages(jsonResponse))
})


fetch('https://siggybyoga.femtoweb.co.uk/api/specialevents/',{
  method:'GET',
}).then(response =>{
  return response.json();
}).then(jsonResponse =>{
  store.dispatch(setSpecialEvents(jsonResponse))
})

const handleSession = async () => {
  try {
    let response = await fetch("https://siggybyoga.femtoweb.co.uk/api/token/refresh/", {
      method: "POST",
      credentials: 'include' // Ensure cookies are included
    });

    if (response.ok) {
      const data = await response.json();
      store.dispatch(loginSuccess(data));
      resetLogoutTimer(store.dispatch);
      return;
    }

    if (response.status === 401) {
      // Clear local storage and reset redux state on 401 response
      localStorage.clear();
      store.dispatch(logout());
      // Redirect to login or other appropriate actions
    }


    // If refresh fails, try to verify the session
    response = await fetch("https://siggybyoga.femtoweb.co.uk/api/verify_session/", {
      method: "GET",
      credentials: "include", // Include credentials to send the HTTPOnly cookie
    });

    if (response.ok) {
      const data = await response.json();
      store.dispatch(loginSuccess(data.user));
      resetLogoutTimer(store.dispatch);
    } else {
      
      throw new Error('Session verification failed');
    }
  } catch (error) {

    console.error('Error handling session:', error);
    store.dispatch(logout()); // On error, consider logging out the user
  }
};


handleSession(); // Call this function on app start/reload



root.render(
 
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <GoogleOAuthProvider clientId={GoogleClientID}>
    <App />
    </GoogleOAuthProvider>
   
    </PersistGate>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from '@chakra-ui/react';
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Alert,
    AlertIcon,
    AlertDescription,
} from '@chakra-ui/react';
import { GoogleLogin } from '@react-oauth/google';
import { loginSuccess } from '../../Redux/Actions/RegisterActions';
import { logout } from '../../Redux/Actions/RegisterActions';
import { resetLogoutTimer } from '../../Redux/Utility/Logout';
import { GoogleClientID } from '../../Config/Config';

const LoginForm = () => {
    const dispatch = useDispatch();
    const [loginState, setLoginState] = useState({
        username: '',
        password: '',
    });
    const [loginError, setLoginError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    // const [isError, setError] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginState(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoginError('');
        setLoading(true);

        try {
            const response = await fetch('https://siggybyoga.femtoweb.co.uk/api/login/', {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginState),
            });

            const data = await response.json();

            if (response.ok) {
                setLoading(false);
                setLoggedIn(true);
                dispatch(loginSuccess(data));
                resetLogoutTimer(dispatch);
            } else {
                setLoading(false);
               
                setLoginError(data.error || 'Login failed. Please try again.');
                if (response.status === 401) {
                    localStorage.clear();
                    dispatch(logout());
                }
            }
        } catch (error) {
            setLoading(false);
          
            console.error('Login error!', error);
            setLoginError('Network error, please try again later.');
        }
    };

    const responseGoogleSuccess = async (response) => {
        try {
            const googleResponse = await fetch('https://siggybyoga.femtoweb.co.uk/api/register/google/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: response.credential }),
                credentials:'include'
            });

            const data = await googleResponse.json();

            if (googleResponse.ok) {
                setLoggedIn(true);
                dispatch(loginSuccess(data));
                resetLogoutTimer(dispatch);
            } else {
                setLoginError('Google login failed. Please try again.');
            }
        } catch (error) {
            console.error('Google login error:', error);
            setLoginError('Network error, please try again later.');
        }
    };

    const responseGoogleFailure = (error) => {
        console.error('Google login failed:', error);
        setLoginError('Google login failed. Please try again.');
    };

    return (
        <>
            {isLoading ? (
                <Spinner color="blue.500" size="xl" />
            ) : isLoggedIn ? (
                <Alert status="success">
                    <AlertIcon />
                    You have been logged in
                </Alert>
            ) : (
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        {loginError &&  (
                            <Alert status="error">
                                <AlertIcon />
                                <AlertDescription>{loginError}</AlertDescription>
                            </Alert>
                        )}

                        <FormControl isRequired>
                            <FormLabel htmlFor='username'>Email Address</FormLabel>
                            <Input name='username' id='username' type='text' onChange={handleChange} />
                        </FormControl>

                        <FormControl isRequired>
                            <FormLabel htmlFor='password'>Password</FormLabel>
                            <Input name='password' id='password' type='password' onChange={handleChange} />
                        </FormControl>

                        <Button type='submit' colorScheme='blue'>Login</Button>
                        <GoogleLogin
                            clientId={GoogleClientID}
                            onSuccess={responseGoogleSuccess}
                            onFailure={responseGoogleFailure}
                            cookiePolicy={'single_host_origin'}
                            buttonText="Login with Google"
                        />
                    </VStack>
                </form>
            )}
        </>
    );
};

export default LoginForm;

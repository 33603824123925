import React from 'react'
import { useState } from 'react';
import {
    Input,
    Button,
    VStack,
    useToast,
    Textarea,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text,
} from '@chakra-ui/react'
import '../../assets/style/myAccount.css'
const MyAccountForm = ({ accountDetails }) => {
    const [formData, setFormData] = useState({ ...accountDetails });
    // const [message, setMessage] = useState('');
    // const [file, setFile] = useState(null);
    const toast = useToast();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.files[0]});
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('first_name', formData.first_name);
        formDataToSend.append('last_name', formData.last_name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone_number', formData.phone_number);
        formDataToSend.append('message', formData.message);
        
        if (formData.file) {
            formDataToSend.append('file', formData.file);
        }
    
        try {
            const response = await fetch('https://siggybyoga.femtoweb.co.uk/api/myaccount/', {
                method: 'POST',
                credentials: 'include', // Important for including the HTTPOnly cookie
                body: formDataToSend, // Send the FormData object, not JSON
            });
    
            if (response.ok) {
                const updatedAccountDetails = await response.json();
                setFormData({ ...updatedAccountDetails });
    
                toast({
                    title: 'Account updated.',
                    description: "Your account details have been successfully updated.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                console.error('Failed to update account details:', response.statusText);
                toast({
                    title: 'Failed to update.',
                    description: "We were unable to update your account details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error('Error updating account details:', error);
            toast({
                title: 'Error.',
                description: "An error occurred while updating your account details.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };


    // const PrintData = (e) =>{ 
    //     e.preventDefault();
    //         console.log(formData)
        
    // }
    
    return (

        <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
                <Input name="first_name" value={formData.first_name || ''} onChange={handleChange} />
                <Input name="last_name" value={formData.last_name || ''} onChange={handleChange} />
                <Input name="email" value={formData.email || ''} onChange={handleChange} />
                <Input name="phone_number" value={formData.phone_number || ''} onChange={handleChange} />
                <Text class="previous-data-from-myAccount">Previous Classes</Text>
                <Accordion allowMultiple className='previous-data-accordion'>
                    {accountDetails?
                    accountDetails.classes.length > 0?
                        accountDetails.classes.map((userClass, index) => (
                            <AccordionItem key={index}>
                                <h2>
                                    <AccordionButton className='previous-data-button'>
                                        <Box flex='1' textAlign='left'>
                                            {userClass.class_name}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  {userClass.class_name} on {userClass.date} at {userClass.time} by {userClass.instructor}
                                </AccordionPanel>
                            </AccordionItem>
                        )):<Text>No Previous Classes Has Been Found</Text>:""}
                </Accordion>
                <Text className="previous-data-from-myAccount">Previous Notes</Text>
                <Accordion allowMultiple className='previous-data-accordion'>
                    {accountDetails?
                    accountDetails.notes.length > 0?
                        accountDetails.notes.map((note, index) => (
                            <AccordionItem key={index}>
                                <h2>
                                    <AccordionButton>
                                        <Box flex='1' textAlign='left'>
                                            Note from {new Date(note.created_at).toLocaleString()}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    {note.message}
                                    <br></br>
                                    {note.file && <a href={note.file} target="_blank" rel="noopener noreferrer">View Attachment</a>}
                                </AccordionPanel>
                            </AccordionItem>
                        )):<Text>No Previous Messages Has Been Found</Text>:""}
                </Accordion>
                <Textarea
                    placeholder="Your message here"
                    name='message'
                    value={formData.message}
                    onChange={handleChange}
                />
                <Input
                    name="file"
                    type="file"
                    onChange={(e)=>{handleFileChange(e)}}
                />
                <Button type="submit">Save Changes</Button>
            </VStack>
        </form>

    );
}

export default MyAccountForm
import { useEffect } from 'react';
import axios from 'axios';
import { Button, Box, Heading, Text, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {  useDispatch } from 'react-redux';
import { bookClassSuccess} from '../../Redux/Actions/ClassesActions';
const PaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Define classId and eventId right after component is called
  const queryParams = new URLSearchParams(window.location.search);
  const sessionId = queryParams.get('session_id');
  const classId = queryParams.get('class_id');
  const eventId = queryParams.get('event_id');

  useEffect(() => {
    // Directly book the class or special event after successful payment verification
    const bookClass = async () => {
      try {
        await axios.post(`https://siggybyoga.femtoweb.co.uk/api/classes/book/${classId}/`, { payment_status: 'paid' }, { withCredentials: true });
        dispatch(bookClassSuccess(classId))
        console.log('Class booked successfully');
      } catch (error) {
        console.error("Error booking class:", error);
      }
    };

    const bookEvent = async () => {
      try {
        await axios.post(`https://siggybyoga.femtoweb.co.uk/api/specialevents/${eventId}/`, {}, { withCredentials: true });
        console.log('Special event booked successfully');
      } catch (error) {
        console.error("Error booking special event:", error);
      }
    };

    if (sessionId && classId) {
      bookClass();
    } else if (sessionId && eventId) {
      bookEvent();
    }
  }, [sessionId, classId, eventId]); // Ensure effect hook dependencies are correctly listed

  return (
    <Center h="100vh" flexDirection="column">
      <Box textAlign="center" py={10} px={6}>
        <Heading display="inline-block" as="h2" size="2xl" bgGradient="linear(to-r, teal.400, teal.600)" backgroundClip="text">
          Payment Successful!
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Your payment has been processed successfully. Thank you for your purchase!
        </Text>
        <Text color={'gray.500'}>
          {/* Use the variables directly since they are now scoped correctly */}
          You are now enrolled in the {classId ? 'class' : 'special event'}. Get ready to boost your skills!
        </Text>
        <Button colorScheme="teal" variant="solid" mt={6} onClick={() => navigate('/')}>
          Go to Homepage
        </Button>
      </Box>
    </Center>
  );
};

export default PaymentSuccess;

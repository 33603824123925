export const setClasses = classes => ({
    type: 'SET_CLASSES',
    payload: classes
});

export const bookClassSuccess = (classId) => ({
    type: 'BOOK_CLASS_SUCCESS',
    payload: classId,
  });
  
  export const cancelClassSuccess = (classId) => ({
    type: 'CANCEL_CLASS_SUCCESS',
    payload: classId,
  });

  export const setBookedClasses = bookedClasses => ({
    type: 'SET_BOOKED_CLASSES',
    payload: bookedClasses,
  });


import React, { useState } from 'react';
import { Spinner } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Button,
    VStack,
    Alert,
    AlertIcon,
    AlertDescription,
    CloseButton,
} from '@chakra-ui/react';
import { GoogleLogin } from '@react-oauth/google';
import { loginSuccess } from '../../Redux/Actions/RegisterActions';
import { resetLogoutTimer } from '../../Redux/Utility/Logout';
import { GoogleClientID } from '../../Config/Config';


const RegisterForm = () => {
    const dispatch = useDispatch();
    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        passwordConfirm: '',
    });
    const [errors, setErrors] = useState({});
    const [isRegistered, setIsRegistered] = useState(false);
    const [backendErrorMessage, setBackendErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // const csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = {};

        if (!formState.firstName) newErrors.firstName = 'First name is required';
        if (!formState.lastName) newErrors.lastName = 'Last name is required';
        if (!formState.email) newErrors.email = 'Email is required';
        if (!formState.password) newErrors.password = 'Password is required';
        if (formState.password !== formState.passwordConfirm) newErrors.passwordConfirm = 'Passwords must match';

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setIsLoading(true);
            try {
                const response = await fetch('https://siggybyoga.femtoweb.co.uk/api/register/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: formState.email,
                        first_name: formState.firstName,
                        last_name: formState.lastName,
                        email: formState.email,
                        phone_number: formState.phoneNumber,
                        password: formState.password,
                    }),
                });

                const data = await response.json();

                if (response.ok) {
                    setIsRegistered(true);
                    const loginResponse = await fetch('https://siggybyoga.femtoweb.co.uk/api/login/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            username: formState.email,
                            password: formState.password,
                        }),
                        credentials: 'include',
                    });

                    const loginData = await loginResponse.json();

                    if (loginResponse.ok) {
                        setIsLoading(false);
                        dispatch(loginSuccess(loginData));
                        resetLogoutTimer(dispatch);
                    } else {
                        setIsLoading(false);
                    }
                } else {
                    setIsLoading(false);
                    let errorMessage = 'Registration failed. Please try again.';
                    if (data.email && data.email[0]) {
                        errorMessage = data.email[0];
                    }
                    setBackendErrorMessage(errorMessage);
                }
            } catch (error) {
                setIsLoading(false);
                console.error('There was an error!', error);
            }
        }
    };

    const responseGoogleSuccess = async (response) => {
        setIsLoading(true);
        try {
            const googleResponse = await fetch('https://siggybyoga.femtoweb.co.uk/api/register/google/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: response.credential }),
                credentials:'include'
            });

            if (googleResponse.status === 201 || googleResponse.status === 200) {
                const data = await googleResponse.json();
                dispatch(loginSuccess(data));
                resetLogoutTimer(dispatch);
                setIsLoading(false);
                setIsRegistered(true)
            } else {
                const errorData = await googleResponse.json();
                setBackendErrorMessage(errorData.error || 'Failed to register or login using Google. Please try again.');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Google registration error:', error);
            setBackendErrorMessage('Network error during Google registration.');
            setIsLoading(false);
        }
    };

    const responseGoogleFailure = (error) => {
        console.error('Google registration failed:', error);
        setBackendErrorMessage('Google login failed. Please try again.');
    };

    const hasError = (field) => !!errors[field];

    return (
        <>
            {isRegistered ? (
                <Alert status="success">
                    <AlertIcon />
                    Registration successful! You can now log in.
                </Alert>
            ) : (
                <>
                    {backendErrorMessage && (
                        <Alert status="error">
                            <AlertIcon />
                            <AlertDescription>{backendErrorMessage}</AlertDescription>
                            <CloseButton position="absolute" right="8px" top="8px" onClick={() => setBackendErrorMessage('')} />
                        </Alert>
                    )}
                    {isLoading ? (
                        <Spinner color="blue.500" size="xl" />
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <VStack spacing={4}>
                                <FormControl isInvalid={hasError('firstName')}>
                                    <FormLabel htmlFor='firstName'>First Name</FormLabel>
                                    <Input name='firstName' id='firstName' onChange={handleChange} />
                                    {hasError('firstName') && <FormErrorMessage>{errors.firstName}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={hasError('lastName')}>
                                    <FormLabel htmlFor='lastName'>Last Name</FormLabel>
                                    <Input name='lastName' id='lastName' onChange={handleChange} />
                                    {hasError('lastName') && <FormErrorMessage>{errors.lastName}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={hasError('email')}>
                                    <FormLabel htmlFor='email'>Email</FormLabel>
                                    <Input name='email' id='email' type='email' onChange={handleChange} />
                                    {hasError('email') && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={hasError('phoneNumber')}>
                                    <FormLabel htmlFor='phoneNumber'>Phone Number</FormLabel>
                                    <Input name='phoneNumber' id='phoneNumber' onChange={handleChange} />
                                    {hasError('phoneNumber') && <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={hasError('password')}>
                                    <FormLabel htmlFor='password'>Password</FormLabel>
                                    <Input name='password' id='password' type='password' onChange={handleChange} />
                                    {hasError('password') && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={hasError('passwordConfirm')}>
                                    <FormLabel htmlFor='passwordConfirm'>Confirm Password</FormLabel>
                                    <Input name='passwordConfirm' id='passwordConfirm' type='password' onChange={handleChange} />
                                    {hasError('passwordConfirm') && <FormErrorMessage>{errors.passwordConfirm}</FormErrorMessage>}
                                </FormControl>

                                <Button type='submit' colorScheme='blue'>Register</Button>
                                <GoogleLogin
                                    clientId={GoogleClientID}
                                    onSuccess={responseGoogleSuccess}
                                    onFailure={responseGoogleFailure}
                                    cookiePolicy={'single_host_origin'}
                                    buttonText="Register with Google"
                                />
                            </VStack>
                        </form>
                    )}
                </>
            )}
        </>
    );
};

export default RegisterForm;

// authReducer.js
import { REGISTER_SUCCESS, LOGIN_SUCCESS, LOGOUT } from '../Actions/RegisterActions.js';
// Initial State
const initialState = {
  user: null,
  isLoggedIn: false,
};
  
  // Reducer
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case REGISTER_SUCCESS:
      case LOGIN_SUCCESS:
        return {
          ...state,
          user: action.payload,
          isLoggedIn: true,
        };
      case LOGOUT:
        return {
          ...initialState
        };
      default:
        return state;
    }
  };
  
  export default authReducer;  
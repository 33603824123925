import React, { useEffect, useState } from 'react';
import ClassItem from './ClassItem';
import '../../assets/style/classlist.css';
import { useSelector, useDispatch } from 'react-redux';
import { bookClassSuccess, cancelClassSuccess,setBookedClasses} from '../../Redux/Actions/ClassesActions';

const ClassList = ({ selectedDay }) => {
  const classesData = useSelector(state => state.yogaClasses.classes);
  const bookedClasses = useSelector(state => state.yogaClasses.bookedClasses);
  const user = useSelector(state => state.UserData.user);
  const dispatch = useDispatch();
  const [classes, setClasses] = useState([]);
  useEffect(() => {
    // Assuming user.booked_classes contains the booked class IDs
    if (user && user.booked_classes) {
      dispatch(setBookedClasses(user.booked_classes));
    }
  }, [user, dispatch]);

  const formatDate = (date) => [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0')
  ].join('-');

  useEffect(() => {
    const dateToFilterBy = selectedDay
      ? formatDate(new Date(selectedDay.year, selectedDay.month - 1, selectedDay.day))
      : formatDate(new Date());

    const filteredClasses = classesData.filter(classItem =>
      classItem.date === dateToFilterBy
    );
    setClasses(filteredClasses);
  }, [classesData, selectedDay]);

  const handleBookingSuccess = (classId) => {
    dispatch(bookClassSuccess(classId));
  };

  const handleCancelSuccess = (classId) => {
    dispatch(cancelClassSuccess(classId));
  };

  return (
    <div className='class-list-container'>
      <br></br>
      <div className="class-list">
        {classes.map((classItem) => (
          <ClassItem
            key={classItem.id}
            {...classItem}
            isBooked={bookedClasses.includes(classItem.id)}
            onBookingSuccess={handleBookingSuccess}
            onCancelSuccess={handleCancelSuccess}
          />
        ))}
      </div>
    </div>
  );
};

export default ClassList;

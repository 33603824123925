// ClassesReducers.js
const initialState = {
  classes: [],
  bookedClasses: [],
};

const yogaClassesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CLASSES':
      return {
        ...state,
        classes: action.payload,
      };
    case 'SET_BOOKED_CLASSES':
      return {
        ...state,
        bookedClasses: action.payload,
      };
    case 'BOOK_CLASS_SUCCESS':
      return {
        ...state,
        bookedClasses: [...state.bookedClasses, action.payload],
      };
    case 'CANCEL_CLASS_SUCCESS':
      return {
        ...state,
        bookedClasses: state.bookedClasses.filter(id => id !== action.payload),
      };
    default:
      return state;
  }
};

export default yogaClassesReducer;

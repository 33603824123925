import React, { useState } from 'react'
import '../../assets/style/Tabs.css'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import DateCalender from '../../components/Tabs/Calender.jsx';
import Packages from '../../components/Tabs/Packages.jsx';
import Contact from './Contact.jsx';
import SpecialEvents from './SpecialEvents.jsx';
import { useSelector } from 'react-redux';
const AllTabs = ({ setActiveTab }) => {

  const SpecialEventsData = useSelector(state => state.specialEvents.specialEvents)

  return (
    <div className="tabs">
      <Tabs>
        <TabList>
          <Tab>Schedule</Tab>
          <Tab>Packages</Tab>
          {SpecialEventsData.length > 0 ? <Tab>Special Events</Tab> : ''}
          <Tab>Contact & Info</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <DateCalender />
          </TabPanel>
          <TabPanel>
            <Packages />
          </TabPanel>
          {SpecialEventsData.length > 0 ? <TabPanel>
              <SpecialEvents data={SpecialEventsData}/>
          </TabPanel>
            : ''}

          <TabPanel>
            <Contact />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default AllTabs;